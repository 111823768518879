export function runHomeScripts() {
  const dynamicData = [
    {
      title1: 'Startups',
      title2: 'acquire first 1000 users',
      descTitle: 'A restaurant rating app for Europe gets first 1000 users.',
      descContent:
        'Method: Answering questions of foodies looking for places to eat in Europe.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379074/armada-website/image_1.png',
      class: 'color-1'
    },
    {
      title1: 'CXOs',
      title2: 'become thought leaders',
      descTitle:
        'A Crypto Tax Startup CEO from the US builds his personal brand.',
      descContent:
        'Method: Engaging in conversations focused on questions around crypto taxes and compliances.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379072/armada-website/image_2.png',
      class: 'color-2'
    },
    {
      title1: 'Content creators',
      title2: 'distribute content',
      descTitle:
        'A Wellness Blogger from Mexico connects with relevant users to share content.',
      descContent:
        'Method: Engaging in conversations focused on complaints around insomnia or Sleep problems.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379069/armada-website/image_3.png',
      class: 'color-3'
    },
    {
      title1: 'market-researchers',
      title2: 'get consumer insights',
      descTitle:
        'A SAAS chatbot company finds strong user-insights to crack the product-market fit faster.',
      descContent:
        'Method: Researching/listening to conversations around chatbots.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379072/armada-website/image_4.png',
      class: 'color-4'
    },
    {
      title1: 'sales teams',
      title2: 'engage with potential customers',
      descTitle:
        'A Pantyhose company from Canada does effective brand building.',
      descContent:
        'Method: Engaging in every conversation focused around pantyhose problems.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379073/armada-website/image_5.png',
      class: 'color-1'
    },
    {
      title1: 'product teams',
      title2: 'do need-analysis',
      descTitle:
        "A food delivery startup's product team redesign their product based on user insights.",
      descContent:
        'Method: Researching/listening to conversations focused on complains about food delivery.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379071/armada-website/image_6.png',
      class: 'color-2'
    },
    {
      title1: 'Makers',
      title2: 'acquire first 1000 users',
      descTitle:
        'A Podcast App developer from Texas increases his downloads by 30%.',
      descContent:
        'Method: Engaging in conversations focused on buying intention for a podcast app.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379072/armada-website/image_7.png',
      class: 'color-3'
    },
    {
      title1: 'Marketers',
      title2: 'make their product a talking point',
      descTitle:
        'A New York based Skincare company increases its userbase by 70%.',
      descContent:
        'Method: Engaging in conversations focused on complains about acne, pimples, dry/oily skin.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379078/armada-website/image_8.png',
      class: 'color-4'
    },
    {
      title1: 'Analysts',
      title2: 'dig specific/actionable insights',
      descTitle:
        "A Coffee company get insights around its competitor's product and user.",
      descContent:
        'Method: Researching/listening to conversations around Starbucks.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379076/armada-website/image_9.png',
      class: 'color-1'
    },
    {
      title1: 'Professionals',
      title2: 'get discovered',
      descTitle:
        'A Startup Lawyer from Seattle increases his clientele by 200%.',
      descContent:
        'Method: Engaging in conversations related to termsheets/SAFE notes for startups.',
      image:
        'https://res.cloudinary.com/thinkcdnimages/image/upload/v1563379074/armada-website/image_10.png',
      class: 'color-2'
    }
  ];

  // *variables
  var indexes = {
    currentIndex: 0
  };

  let userEmail;

  const heroTitleContainer = document.getElementById('hero-title-container');

  const descriptionContainer = document.getElementById('description-container');

  const imageContainer = document.getElementById('description-image-container');

  const previousButton = document.getElementById('previous-button');

  const nextButton = document.getElementById('next-button');

  const counter = document.getElementById('page-counter');

  const withInviteActions = document.querySelectorAll('.with-invite');
  const withoutInviteActions = document.querySelectorAll('.without-invite');
  const sendMeInviteNavbar = document.querySelector('#sendMeInviteNav');

  let sendMeInviteNavbarVisible = false;

  const banner = document.querySelector('.banner');

  var urlParams = new URLSearchParams(window.location.search);
  var entries = urlParams.entries();
  //   console.log(entries, 'entries');
  const queryParams = {};
  for (let pair of entries) {
    queryParams[pair[0]] = pair[1];
  }
  if (queryParams.join == '2') {
    banner.style.display = 'block';
  } else {
    banner.style.display = 'none';
  }
  // if (queryParams.join === undefined) {
  //   withInviteActions.forEach(action => (action.style.display = 'none'));
  //   withoutInviteActions.forEach(action => (action.style.display = 'inline'));
  // } else {
  //   withoutInviteActions.forEach(action => (action.style.display = 'none'));
  //   withInviteActions.forEach(action => (action.style.display = 'inline'));
  // }

  withInviteActions.forEach(action => (action.style.display = 'inline'));
  withoutInviteActions.forEach(action => (action.style.display = 'none'));
  sendMeInviteNavbar.style.display = 'none';

  //   event listeners

  withoutInviteActions.forEach(elem => {
    elem.addEventListener('click', sendMeInvite);
  });

  withInviteActions.forEach(elem => {
    elem.addEventListener('click', () => {
      const target = elem.getAttribute('data-target');
      redirectUser(target);
    });
  });

  previousButton.addEventListener('click', () => {
    requestPreviousSlide();
  });

  nextButton.addEventListener('click', () => {
    requestNextSlideManually();
  });

  // preload images

  Promise.resolve().then(() => {
    dynamicData
      .map(d => d.image)
      .forEach(imgSrc => {
        const img = new Image();
        img.src = imgSrc;
      });
  });

  // *functions

  // if (queryParams.join === undefined) {
  //   document.addEventListener('scroll', () => {
  //     if (window.pageYOffset > 400 && !sendMeInviteNavbarVisible) {
  //       sendMeInviteNavbar.style.display = 'block';
  //       sendMeInviteNavbarVisible = true;
  //     } else if (window.pageYOffset < 400 && sendMeInviteNavbarVisible) {
  //       sendMeInviteNavbar.style.display = 'none';
  //       sendMeInviteNavbarVisible = false;
  //     }
  //   });
  // }

  const requestAnimations = function(delay, ...fn) {
    var requestAnimFrame = (function() {
        return (
          window.requestAnimationFrame ||
          function(callback, element) {
            window.setTimeout(callback, 1000 / 60);
          }
        );
      })(),
      start = new Date().getTime(),
      handle = {};
    function loop() {
      handle.value = requestAnimFrame(loop);
      var current = new Date().getTime(),
        delta = current - start;
      if (delta >= delay) {
        // fn.call();
        fn.forEach(func => func.call());
        if (indexes.currentIndex === dynamicData.length - 1) {
          indexes.currentIndex = 0;
        }
        indexes.currentIndex = indexes.currentIndex + 1;
        counter.innerHTML = `${indexes.currentIndex} / ${dynamicData.length -
          1}`;

        previousButton.disabled = false;
        start = new Date().getTime();
      }
    }
    handle.value = requestAnimFrame(loop);
    return handle;
  };

  function getNextTitle(title1, title2, classNumber) {
    return `<div id="hero-title" title="${classNumber}" class="text-center lg:text-left px-4 lg:px-0 sm:text-center w-full text-xl lg:text-3xl flex-grow font-bold heading animated-title">
            Enabling <span class="heading">${title1}</span> to <span class="heading">${title2}</span></div>`;
  }

  function getDescription(descTitle, descContent, descSubtitle) {
    return `<div id="description">
          <h3 class="text-xl lg:text-3xl text-left font-bold leading-tight description-title text-dark-800 heading text-center lg:text-left mx-4 lg:mx-0">
            ${descTitle}
          </h3>
          <p class="text-lg lg:text-xl mt-8 mx-4 lg:mx-0 leading-normal description-content">
          ${descContent}
          </p>
        </div>`;
  }

  function getImage(index) {
    return `<img src="${
      dynamicData[index].image
    }" class=" animated-image w-full" alt="">`;
  }

  function changeTitles() {
    const heroTitle = document.getElementById('hero-title');
    heroTitle.classList.remove('animated-title');
    heroTitle.classList.add('removing-title');
    heroTitle.remove();
    const div = document.createElement('div');
    div.innerHTML = getNextTitle(
      dynamicData[indexes.currentIndex].title1,
      dynamicData[indexes.currentIndex].title2,
      dynamicData[indexes.currentIndex].class
    );
    heroTitleContainer.appendChild(div.firstChild);
  }

  function changeDescription() {
    const description = document.getElementById('description');
    description.classList.remove('animated-description');
    description.classList.add('removing-description');
    // description.addEventListener('animationend', () => {

    // });
    description.remove();
    const div = document.createElement('div');
    div.innerHTML = getDescription(
      dynamicData[indexes.currentIndex].descTitle,
      dynamicData[indexes.currentIndex].descContent,
      dynamicData[indexes.currentIndex].descSubtitle
    );
    descriptionContainer.appendChild(div.firstChild);
  }

  function changeImage() {
    const image = document.querySelector('#description-image-container img');
    image.classList.remove('animated-image');
    image.classList.add('removing-title');
    image.remove();
    const div = document.createElement('div');
    div.innerHTML = getImage(indexes.currentIndex);
    imageContainer.appendChild(div.firstChild);
    enableButtons();
  }

  function changeBackground() {
    const container = document.querySelector('.preview-section');
    container.setAttribute('id', dynamicData[indexes.currentIndex].class);
  }

  function setTitleColor(colorNumber) {
    const heroTitle = document.getElementById('hero-title');
    heroTitle.setAttribute('title', colorNumber);
  }

  function requestNextSlide() {
    if (indexes.currentIndex === dynamicData.length - 1) {
      indexes.currentIndex = 0;
    } else {
      indexes.currentIndex = indexes.currentIndex + 1;
    }
    disableButtons();
    changeBackground();
    changeImage();
    changeDescription();
    counter.innerHTML = `${indexes.currentIndex + 1} / ${dynamicData.length}`;
    previousButton.removeAttribute('disabled');
  }

  function requestPreviousSlide() {
    typewriter.stop();
    previousButton.disabled = true;
    if (indexes.currentIndex == 0) {
      previousButton.disable = true;
      previousButton.setAttribute('disabled', 'true');
    } else {
      indexes.currentIndex = indexes.currentIndex - 1;
      changeBackground();
      changeTitles();
      changeImage();
      changeDescription();
      counter.innerHTML = `${indexes.currentIndex + 1} / ${dynamicData.length}`;
    }
  }

  function disableButtons() {
    nextButton.disabled = true;
  }

  function enableButtons() {
    nextButton.disabled = false;
    previousButton.disabled = false;
  }

  const title = document.getElementById('hero-title');
  var typewriter = new Typewriter(title, {
    loop: true,
    changeDeleteSpeed: 100,
    delay: 10
  });
  typewriter
    .callFunction(() => setTitleColor('color-1'))
    .changeDelay(50)
    .changeDeleteSpeed(30)
    .typeString(
      'Enabling ' + dynamicData[0].title1 + ' to ' + dynamicData[0].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-2');
    })
    .typeString(
      'Enabling ' + dynamicData[1].title1 + ' to ' + dynamicData[1].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-3');
    })
    .typeString(
      'Enabling ' + dynamicData[2].title1 + ' to ' + dynamicData[2].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-4');
    })
    .typeString(
      'Enabling ' + dynamicData[3].title1 + ' to ' + dynamicData[3].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-1');
    })
    .typeString(
      'Enabling ' + dynamicData[4].title1 + ' to ' + dynamicData[4].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-2');
    })
    .typeString(
      'Enabling ' + dynamicData[5].title1 + ' to ' + dynamicData[5].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-3');
    })
    .typeString(
      'Enabling ' + dynamicData[6].title1 + ' to ' + dynamicData[6].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-4');
    })
    .typeString(
      'Enabling ' + dynamicData[7].title1 + ' to ' + dynamicData[7].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-1');
    })
    .typeString(
      'Enabling ' + dynamicData[8].title1 + ' to ' + dynamicData[8].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-2');
    })
    .typeString(
      'Enabling ' + dynamicData[9].title1 + ' to ' + dynamicData[9].title2
    )
    .pauseFor(2500)
    .deleteAll(30)
    .callFunction(() => {
      requestNextSlide();
      setTitleColor('color-3');
    })
    .start();

  function requestNextSlideManually() {
    if (indexes.currentIndex === dynamicData.length - 1) {
      indexes.currentIndex = 0;
    } else {
      indexes.currentIndex = indexes.currentIndex + 1;
    }
    typewriter.stop();
    disableButtons();
    // cancelAnimationFrame(animationAutoPlay.value);
    changeBackground();

    changeTitles();
    changeImage();
    changeDescription();
    counter.innerHTML = `${indexes.currentIndex + 1} / ${dynamicData.length}`;
    previousButton.removeAttribute('disabled');
  }

  function sendMeInvite() {
    Swal.fire({
      title: 'Request Access to Armada Beta',
      text: 'Please enter your email address to request an invite.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'hello@example.com'
      },
      // showCancelButton: true,
      confirmButtonText: 'Send me an invite',
      showLoaderOnConfirm: true,
      preConfirm: email => {
        userEmail = email;
        return axios
          .get(`https://api.getarmada.app/api/waitlist?email=${email}`)
          .then(response => {
            return response;
          })
          .catch(error => {
            Swal.showValidationMessage(error.response.data.message);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (
        result.value &&
        result.value.status == 200 &&
        result.value.data.type == 'new'
      ) {
        Swal.fire({
          title: `Thank you!`,
          html: `<h3 class="text-xl font-semibold mb-2">We can't wait to get you started!</h3>
                If you want to skip the wait, the fastest way is to schedule a demo with our product expert.`,
          confirmButtonText: 'Schedule a demo',
          customClass: {
            title: 'success-title-swal'
          }
        })
          .then(val => {
            if (val.value == true) {
              window.open(
                `https://calendly.com/team-armada/schedule-demo?email=${userEmail}`,
                '_blank'
              );
            }
          })
          .catch(err => 0);
      } else if (
        result.value &&
        result.value.status == 200 &&
        result.value.data.type == 'old'
      ) {
        Swal.fire({
          title: `<h1>You’re already on our list!</h1>`,
          html: `If you want to skip the wait, the fastest way is to schedule a demo with our product expert.`,
          confirmButtonText: 'Schedule a demo',
          customClass: {
            title: 'success-title-swal'
          }
        })
          .then(val => {
            if (val.value == true) {
              window.open(
                `https://calendly.com/team-armada/schedule-demo?email=${userEmail}`,
                '_blank'
              );
            }
          })
          .catch(err => 0);
      }
    });
  }

  function redirectUser(campaign) {
    let query;
    let utmCampaigns = [
      'banner',
      'hero-section',
      'navbar-button',
      'pricing-section'
    ];
    if (!queryParams.utm_source) {
      queryParams.utm_source = 'direct';
    }
    if (!queryParams.utm_medium) {
      queryParams.utm_medium = 'website';
    }

    if (
      !queryParams.utm_campaign ||
      utmCampaigns.includes(queryParams.utm_campaign)
    ) {
      queryParams.utm_campaign = campaign;
    }

    var queryString = Object.keys(queryParams)
      .map(function(key) {
        return key + '=' + queryParams[key];
      })
      .join('&');

    // console.log(queryString, queryParams);

    window.open('https://web.getarmada.app?' + queryString, '_blank');
  }
}
