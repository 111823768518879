import './fonts/libre-baskerville-v5-latin-regular.woff';
import './fonts/libre-baskerville-v5-latin-regular.woff2';
import './index.scss';
import './scripts/script.js';

import { runHomeScripts } from './scripts/home.scripts';

if (window.location.pathname === '/') {
  runHomeScripts();
}

